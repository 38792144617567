import { z } from 'zod'
import { datetimeLocal } from '../../helper'

export enum FeeCaseType {
  Best = 'Best',
  Base = 'Base',
  Worst = 'Worst',
}

export const ExchangeTypeArray = ['CEX', 'DEX'] as const
export const ExchangeTypeEnum = z.enum(ExchangeTypeArray)

export const TExchange = z.object({
  id: z.string(),
  name: z.string(),
  logoUrl: z.string().nullable().optional(),
  type: ExchangeTypeEnum,
  deletedAt: datetimeLocal.nullable().optional(),
  description: z.string().nullable().optional(),
  commentary: z.string().nullable().optional(),
  rank: z.number().int().nullable().optional(),
})

export const TRankedExchange = z.object({
  id: z.string(),
  name: z.string(),
  image: z.string(),
  type: ExchangeTypeEnum,
  rank: z.number().int(),
  description: z.string(),
})

export type RankedExchange = z.infer<typeof TRankedExchange>

export const TExchangesRankedResponse = z.array(TRankedExchange)

export const TPreferences = z.object({
  tokenSupply: z.number().int(),
  tokenFDV: z.number().int(),
  tgeDate: datetimeLocal.nullable().optional(),
})
export type Preferences = z.infer<typeof TPreferences>

export const TListingFeesRequest = z.object({
  tokenSupply: z.string().transform(Number),
  tokenFDV: z.string().transform(Number),
  exchanges: z.array(z.string()),
  feeCaseType: z.nativeEnum(FeeCaseType).optional(),
})

export type ListingFeesRequest = z.infer<typeof TListingFeesRequest>

export const feeDenominationItem = z.object({
  stablecoin: z.number().int(),
  token: z.number().int(),
  tokenSupplyPercentage: z.number(),
  totalUSD: z.number().int(),
})

export const TListingFee = z.object({
  feeBreakdownByExchange: z.array(
    z.object({
      exchange: z
        .object({
          id: z.string(),
          name: z.string().nullable().optional(),
          rank: z.number().int().nullable().optional(),
        })
        .nullable()
        .optional(),
      nonRefundableFees: feeDenominationItem,
      nonRefundablePercentage: z.number(),
      refundableFees: feeDenominationItem,
      refundablePercentage: z.number(),
      total: feeDenominationItem,
    }),
  ),
  feeSummaryByCategory: z.object({
    listingAndIntegrationFee: z.number().int(),
    marketingBudget: z.number().int(),
    securityDeposit: z.number().int(),
    marketMakerDeposit: z.number().int(),
  }),
})

export const TListingFeesResponse = z.object({
  worst: TListingFee,
  base: TListingFee,
  best: TListingFee,
})

export const TExchangeCostBreakdown = z.object({
  id: z.string(),
  name: z.string(),
  logoUrl: z.string().nullable().optional(),
  rank: z.number().int(),
  dueDiligenceDescription: z.string().nullable().optional(),
  hasListingFees: z.boolean(),
  hasSecurityDeposit: z.boolean(),
  estimatedListingFees: z.number(),
})
export type ExchangeCostBreakdown = z.infer<typeof TExchangeCostBreakdown>

export const TCostBreakdownByExchangeResponse = z.array(TExchangeCostBreakdown)

export const TExchangeProfile = z.object({
  id: z.string().trim().min(1),
  name: z.string().trim().min(1),
  logoUrl: z.string().trim().min(1).nullable(),
  rank: z.number().int().positive(),
  commentary: z.string().nullable(),
  pricePerformanceRating: z.number().int().min(1).max(5),
  tradingKpisRating: z.number().int().min(1).max(5),
  costAndAccessibilityRating: z.number().int().min(1).max(5),
  estimatedFees: z.number().nonnegative(),
  demographics: z.object({
    northAmerica: z.number().min(0).max(100),
    southAmerica: z.number().min(0).max(100),
    europe: z.number().min(0).max(100),
    middleEast: z.number().min(0).max(100),
    africa: z.number().min(0).max(100),
    oceania: z.number().min(0).max(100),
    asia: z.number().min(0).max(100),
  }),
})

export type ExchangeProfile = z.infer<typeof TExchangeProfile>
export type ListingFeesRequestParams = z.infer<typeof TListingFeesRequest>
