import { initContract } from '@ts-rest/core'
import { z } from 'zod'
import {
  TCostBreakdownByExchangeResponse,
  TExchangeProfile,
  TExchangesRankedResponse,
  TListingFeesRequest,
  TListingFeesResponse,
  TPreferences,
} from '../types'
import { ProjectPathParam } from '../types/common'

const c = initContract()

export const exchangeListingContract = c.router({
  getRankedExchanges: {
    method: 'GET',
    path: `exchange-listing/exchanges/ranked`,
    responses: {
      200: TExchangesRankedResponse,
    },
    query: z.object({
      limit: z.number({ coerce: true }).default(10),
    }),
    summary: 'Get ranked exchanges',
  },
  getPreferences: {
    method: 'GET',
    path: `exchange-listing/preferences/:projectId`,
    responses: {
      200: TPreferences,
    },
    pathParams: ProjectPathParam,
    summary: 'Get preferences',
  },
  getExchangeProfile: {
    method: 'GET',
    path: `exchange-listing/exchange-profile/:projectId`,
    query: z.object({
      exchangeId: z.string().trim().min(1),
    }),
    pathParams: ProjectPathParam,
    responses: {
      200: TExchangeProfile,
    },
    summary: 'Get preferences',
  },
  getListingFees: {
    method: 'GET',
    path: `exchange-listing/listing-fees`,
    responses: {
      200: TListingFeesResponse,
    },
    query: TListingFeesRequest,
    summary: 'Get listing fee calculations',
  },
  getCostBreakdownByExchange: {
    method: 'GET',
    path: `exchange-listing/cost-breakdown-by-exchange/:projectId`,
    responses: {
      200: TCostBreakdownByExchangeResponse,
    },
    pathParams: ProjectPathParam,
    query: z.object({
      limit: z.number({ coerce: true }).default(10),
    }),
    summary: 'Get cost breakdown by exchange',
  },
})

export type ExchangeListingContractType = typeof exchangeListingContract
